<template>
  <!-- 教师节领取洗衣机活动 -->
  <div class="list-two">
    <div class="filter-container">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="客户姓名">
          <el-input size="small"
            clearable
            v-model="formInline.customerName"
            placeholder="请输入客户姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户电话">
          <el-input size="small"
            clearable
            v-model="formInline.customerTel"
            placeholder="请输入客户电话"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList(true)" size="small">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" style="max-height: 450px; overflow-y: auto; width: 100%" height="450" border size="small"
      stripe tooltip-effect="dark">
      <el-table-column prop="customerName" label="客户姓名" width="140">
        <template v-slot="scope">
          <span style="color: #f2694a">{{ scope.row.customerName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="customerTel" label="客户电话" width="160"></el-table-column>
      <el-table-column prop="customerAddress" label="相关信息" width="200"></el-table-column>
      <el-table-column prop="storeName" label="核销门店" width="200"></el-table-column>
      <el-table-column prop="submission_time"  label="核销时间"></el-table-column>
      <el-table-column width="280" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button 
          :class="['button-width']"
            :disabled="scope.row.redeemed === 1"
            @click="redeemGift(scope.row)"
            size="mini"
            :type="scope.row.redeemed ? 'success' : 'primary'"
            >{{ scope.row.redeemed === 1 ? "已领取" : "领取礼品" }}</el-button>
            
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getRegListTeach, redeemCustomerTeach } from "../../api/user";

export default {
  data() {
    return {
      formInline: {
        customerName: "",
        customerTel: "",
      },
      search: '', // 用于搜索的字段
      tableData: [],
      total: 0,
      pageNo: 1,
      pageSize: 10,
    };
  },
  
 methods: {
    async getList(isSearch = false) {
      // 如果是搜索操作，强制设置当前页数为1
      if (isSearch) {
        this.pageNo = 1;
      }

      const params = {
        customerName: this.formInline.customerName,
        customerTel: this.formInline.customerTel,
        search: this.search, // 添加搜索的参数
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };

      try {
        const res = await getRegListTeach(params);
        const { records, total } = res.data.data.data;
        records.forEach(record => {
          record.redeemed = record.redeemed || false;
        });
        this.tableData = records;
        this.total = total;
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    },

    handleSizeChange(size) {
      this.pageSize = size;
      this.getList();
    },

    handleCurrentChange(page) {
      this.pageNo = page;
      this.getList();
    },

    // 更新核销状态函数
    async redeemGift(row) {
      this.$confirm("确定进行核销吗?", "提示", {
        confirmButtonText: "确认核销",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const user = JSON.parse(localStorage.getItem('user')); 
          const username = user[0].username; 

          // 根据当前登录用户 username 判断领取门店 
          let storeName; // 门店名称
          if (username === 'root') {
            storeName = 'adminNUll';
          } 
          // 常规门店
          else if (username === 'gulou') {
            storeName = '东鸽电器鼓楼店';
          }
           else if (username === 'xingan') {
            storeName = '东鸽电器兴安店';
          }
          else if (username === 'wenhua') {
            storeName = '东鸽电器文化宫店';
          } 
          else if (username === 'jinsheng') {
            storeName = '东鸽电器金盛店';
          } 
          else if (username === 'yuanhe') {
            storeName = '东鸽电器元和店';
          } 
          else if (username === 'zhongshan') {
            storeName = '达兴利电器中山店';
          } 
          else if (username === 'jinniu') {
            storeName = '达兴利电器金牛店';
          } 
          else if (username === 'minzu') {
            storeName = '民族商场';
          } 
          else if (username === 'tushu') {
            storeName = '苏宁易购图书大厦店';
          } 
          else if (username === 'snzhongshan') {
            storeName = '苏宁易购中山路店';
          } 
          else if (username === 'sngulou') {
            storeName = '苏宁易购鼓楼店';
          } 
          // 商务局
          else if (username === 'shangwuju') {
            storeName = '商务局';
          } 

          else if (username === 'jinniu') {
            storeName = '金川店';
          }
          
          else if (username === 'haoqinying') {
            storeName = '东鸽电器毫沁营店';
          } 
          else if (username === 'wanzheng') {
            storeName = '东鸽电器万正店';
          } 
          else if (username === 'jinyu') {
            storeName = '东鸽电器金宇店';
          } 
          else if (username === 'wendu') {
            storeName = '东鸽电器闻都店';
          } 
          else if (username === 'zhaojun') {
            storeName = '东鸽电器昭君路店';
          } 
          else {
            // 默认或其他情况的处理
            storeName = '未知门店';
          }
      
          const arr = {
            id: row.id,
            redeemed: true,
            redeemedBy: username, 
            storeName: storeName, // 将门店名称添加到发送的数据中
          };


          try {
            const res = await redeemCustomerTeach(arr);
            if (res.data.code === 200) {
              this.$message.success("领取成功");
              row.redeemed = 1;
              row.redeemedBy = username;
            } else {
              this.$message.error("更新失败");
            }
            this.getList();
          } catch (err) {
            this.$message.error("领取失败，请重试");
            console.log("error submit!! 报错");
          }
        })
        .catch(() => {
          console.log("取消领取");
        });
    },

    // async redeemGift(row) {
    //   this.$confirm("确定领取?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(async () => {
    //       const user = JSON.parse(localStorage.getItem('user')); 
    //       const username = user[0].username; 
      
    //       const arr = {
    //         id: row.id,
    //         redeemed: true,
    //         redeemedBy: username, 
    //       };

    //       try {
    //         const res = await redeemCustomerTeach(arr);
    //         if (res.data.code === 200) {
    //           this.$message.success("领取成功");
    //           row.redeemed = 1;
    //           row.redeemedBy = username;
    //         } else {
    //           this.$message.error("更新失败");
    //         }
    //         this.getList();
    //       } catch (err) {
    //         this.$message.error("领取失败，请重试");
    //         console.log("error submit!! 报错");
    //       }
    //     })
    //     .catch(() => {
    //       console.log("取消领取");
    //     });
    // },
  },

  created() {
    this.getList();
  },
};
</script>

<style scoped>
.button-width {
  width: 85px;
}
.el-table thead {
  color: #1f2225 !important;
}
</style>

<style lang="scss" scoped>
.el-main {
  padding: 0 !important;
}
</style>
